import { LayoutsEnum, RoutesEnum } from '@/config/router.config'
import { page } from '@/utils/router.utils'

export default [
  page('', RoutesEnum.Default, () => import('@/pages/HomePage.vue')),
  page('safu/:slug', RoutesEnum.Safu, () => import('@/pages/SafuPage.vue')),

  page('demo/nft-box', RoutesEnum.DemoNftBox, () => import('@/pages/demo/NftBoxPage.vue'), {
    layout: LayoutsEnum.Demo
  }),
  // TODO: not implemented yet
  page('demo/swap', RoutesEnum.DemoNftSwap, () => import('@/pages/demo/SwapPage.vue'), {
    layout: LayoutsEnum.Demo
  }),
  // TODO: not implemented yet
  page('demo/nft-staking', RoutesEnum.DemoNftStaking, () => import('@/pages/demo/NftStakingPage.vue'), {
    layout: LayoutsEnum.Demo
  }),
  // TODO: not implemented yet
  page('demo/nft-game', RoutesEnum.DemoNftGame, () => import('@/pages/demo/NftGamePage.vue'), {
    layout: LayoutsEnum.Demo
  }),
  page('demo/nft-crafting', RoutesEnum.DemoNftCrafting, () => import('@/pages/demo/NftCraftingPage.vue'), {
    layout: LayoutsEnum.Demo
  })
]
