import { type RouteRecordRaw, type Router, type RouterScrollBehavior } from 'vue-router'

import { GlobalMiddlewares, LayoutsEnum, RoutesEnum } from '@/config/router.config'
import routes from '@/routes'
import { page } from '@/utils/router.utils'

export const routerOptions: {
  routes: RouteRecordRaw[]
  linkExactActiveClass: string
  scrollBehavior: RouterScrollBehavior
} = {
  // prettier-ignore
  routes: [
    ...routes,
    page('404', '404', {}, { layout: LayoutsEnum.Error })
  ],
  linkExactActiveClass: 'is-active',
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
}

export function createRouter(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const middleware = GlobalMiddlewares

    if (Array.isArray(to.meta.middleware)) {
      middleware.push(...to.meta.middleware)
    }

    for (let i = 0; i < middleware.length; i++) {
      // @ts-ignore
      const value = await middleware[i](to, from)

      if (typeof value === 'object') return next(value)

      if (value === false) return next({ name: RoutesEnum.Default })
    }

    return next()
  })

  router.afterEach(async (to) => {
    if (to.hash) {
      router.replace({ ...to, hash: '' })
    }
  })
}
