import layoutMiddleware from '@/middleware/layout.middleware'

export enum RoutesEnum {
  Default = 'home',
  Safu = 'safu',
  DemoNftBox = 'demo.nft_box',
  DemoNftSwap = 'demo.nft_swap',
  DemoNftStaking = 'demo.nft_staking',
  DemoNftGame = 'demo.nft_game',
  DemoNftCrafting = 'demo.nft_crafting'
}

export enum LayoutsEnum {
  Default = 'DefaultLayout',
  Error = 'ErrorLayout',
  Demo = 'DemoLayout'
}

export const GlobalMiddlewares = [layoutMiddleware]
