<script setup lang="ts">
import { ModalTarget } from '@kolirt/vue-modal'
import { type Component, computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'

import SymbolDefs from '@/assets/images/useful/svg/theme/symbol-defs.svg?component'

import { RoutesEnum } from './config/router.config'

const route = useRoute()

const layout = computed<Component | null>(() => {
  return route.meta.layout.isError404
    ? defineAsyncComponent(() => import('@/layouts/ErrorLayout.vue'))
    : route.meta.layout.component
})

function checkRoute(): string {
  let currentClass = ''
  switch (route.name) {
    case RoutesEnum.DemoNftBox:
    case RoutesEnum.DemoNftSwap:
    case RoutesEnum.DemoNftStaking:
    case RoutesEnum.DemoNftCrafting:
    case RoutesEnum.DemoNftGame:
      currentClass = 'nft-wrapper'
      break
    default:
      currentClass = ''
  }
  return currentClass
}

const isDemoClass = computed(() => {
  return checkRoute()
})
</script>

<template>
  <div class="l-wrapper" :class="isDemoClass">
    <component :is="layout">
      <RouterView />
    </component>

    <ModalTarget />
  </div>

  <SymbolDefs />
</template>

<style scoped></style>
