import App from '@/App.vue'

import { ViteSSG, type ViteSSGContext } from 'vite-ssg'

import '@/assets/css/all.css'
import modal from '@/plugins/modal'
import { createRouter, routerOptions } from '@/plugins/router'

// @ts-ignore
export const createApp: Promise<ViteSSGContext<true>> = ViteSSG(App, routerOptions, ({ router, app }) => {
  createRouter(router)
  app.use(modal)
})
