import { createModal } from '@kolirt/vue-modal'

export default createModal({
  animationType: 'none',
  modalStyle: {
    align: 'center'
  },
  overlayStyle: {
    'background-color': 'rgba(27, 27, 27, 0.9)'
  }
})
