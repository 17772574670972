import { shallowReactive } from 'vue'
import type { RouteMeta } from 'vue-router'

import { LayoutsEnum } from '@/config/router.config'

interface Meta {
  layout?: LayoutsEnum
}

export function getDefaultRouteMeta(): RouteMeta {
  return {
    layout: shallowReactive({
      type: undefined,
      component: null,
      isError404: false
    })
  }
}

export function page(path: string, name: string, component: any, customMeta: Meta = {}) {
  const meta = getDefaultRouteMeta()
  meta.layout.type = customMeta.layout || LayoutsEnum.Default

  return { path: `/${path}`, name, component, meta }
}
