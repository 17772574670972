import type { RouteLocationNormalized, RouteMeta } from 'vue-router'

import { LayoutsEnum } from '@/config/router.config'
import { getDefaultRouteMeta } from '@/utils/router.utils'

export default async function (to: RouteLocationNormalized): Promise<any> {
  const layout = !to.name ? LayoutsEnum.Error : to.meta.layout.type || LayoutsEnum.Default

  let component: RouteMeta['layout']['component']

  try {
    component = await import(`../layouts/${layout}.vue`)
  } catch (e) {
    component = await import(`../layouts/ErrorLayout.vue`)
  }

  if (!to.name) {
    to.meta = getDefaultRouteMeta()
  }

  to.meta.layout.component = component?.default || null
  to.meta.layout.isError404 = false
}
